import { Col, DatePicker, Form, Select, Row, Button } from "antd";
import React from "react";
const { Item } = Form;
export default function AgentSearchForm({ shop, onSearchOrder }) {
  return (
    <Form onFinish={onSearchOrder}>
      <Item name="id">
        <Select
          showSearch
          style={{
            width: "100%",
          }}
          // placeholder="Search to Select"
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? "").includes(input)
          }
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase())
          }
          options={shop.map((item) => ({
            value: item.id,
            label: item.name,
          }))}
          placeholder="Выберите клиента"
        />
      </Item>
      <br />
      <Row justify="space-between">
        <Col span={12}>
          <Item name="sana1">
            <DatePicker placeholder="Начало периуда" />
          </Item>
        </Col>
        <Col span={12}>
          <Item name="sana2">
            <DatePicker placeholder="Конец периуда" />
          </Item>
        </Col>
      </Row>
      <br />
      <Row justify="end">
        <Item>
          <Button type="primary" htmlType="submit">
            Поиск
          </Button>
        </Item>
      </Row>
    </Form>
  );
}
