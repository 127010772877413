import React from "react";
import { Collapse } from "antd-mobile";
import { numberFormat } from "util/numberFormat";
import { timestempConvert } from "util/tymestempConvector";
import css from "../Orders/OrdersDebtors/StyleOrders.module.css";

export const InfoTable = ({ data, onOrderItem, product, noData }) => {
  const DataUser = data.map((item) => {
    return (
      <Collapse.Panel
        onClick={() => onOrderItem(item.id)}
        key={item.id}
        title={
          <table style={{ width: "100%" }}>
            <tbody>
              <tr>
                {/* <td>{item.name}</td> */}
                <td className={""}>{timestempConvert(item.sana)}</td>
                <td className={""}>{numberFormat(item.summa)}</td>
              </tr>
            </tbody>
          </table>
        }
      >
        <table className={css.OrdersDetailedTable}>
          <thead>
            <tr>
              <th>№</th>
              <th>Nomi</th>
              <th>Soni</th>
              <th>Narxi</th>
              {/* <th>Summa</th> */}
            </tr>
          </thead>
          <tbody>
            {product.length != 0 ? (
              product.map((i, index) => (
                <tr key={i.id} className={css.OrdersDetailedTr}>
                  <td className={css.OrdersDetailedTd}>{index + 1}</td>
                  <td className={css.OrdersDetailedTd} style={{ width: "30%" }}>
                    {i.nomi}
                  </td>
                  <td className={css.OrdersDetailedTd}>{i.soni}</td>
                  <td className={css.OrdersDetailedTd}>{i.price}</td>
                  {/* <td className={css.OrdersDetailedTd}>
                  {numberFormat(i.summa)}
                </td> */}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4}> Загрузка... </td>
              </tr>
            )}
          </tbody>
        </table>
      </Collapse.Panel>
    );
  });

  //   if (data.length === 0) {
  //     return <Preloader />;
  //   }

  return <Collapse accordion>{DataUser}</Collapse>;
};
