import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Typography } from "antd";
import { AutoCenter, Collapse, Toast } from "antd-mobile";
import { numberFormat } from "util/numberFormat";
import Preloader from "../../Loading/Preloader";
import Loding from "../../Loading/Loding";
import { requestFetch } from "Fetch/requestFetch";
import { timestempConvert } from "util/tymestempConvector";
import css from "./StyleOrders.module.css";

const { Title } = Typography;

export const OrdersReportOrderSingle = () => {
  const params = useParams();

  // state загрузчик
  const [isLoding, seIsLoding] = useState(true);

  // state для получения список заказов
  const [ordersReport, setOrdersReport] = useState([]);

  const GetProduct = useCallback(() => {
    requestFetch({
      value: { shop_id: params.id },
      path: "get-shop-order-singl",
    })
      .then((res) => {
        setOrdersReport(res.data);
        seIsLoding(false);
      })
      .catch((error) => {
        seIsLoding(false);
        Toast.show({
          content: `${error}`,
          //  afterClose: () => {
          //    console.log("after");
          //  },
        });
      });
  }, [params.id]);
  useEffect(() => {
    GetProduct();
  }, [GetProduct]);

  const DataUser = ordersReport.map((item) => {
    return (
      <Collapse.Panel
        key={item.id}
        title={
          <table style={{ width: "100%" }}>
            <tbody>
              <tr>
                {/* <td>{item.name}</td> */}
                <td className={""}>{timestempConvert(item.date)}</td>
                <td className={""}>{numberFormat(item.summa)}</td>
              </tr>
            </tbody>
          </table>
        }
      >
        <table className={css.OrdersDetailedTable}>
          <thead>
            <tr>
              <th>№</th>
              <th>Nomi</th>
              <th>Soni</th>
              <th>Narxi</th>
              <th>Summa</th>
            </tr>
          </thead>
          <tbody>
            {item.products.map((i, index) => (
              <tr key={i.id} className={css.OrdersDetailedTr}>
                <td className={css.OrdersDetailedTd}>{index + 1}</td>
                <td className={css.OrdersDetailedTd} style={{ width: "30%" }}>
                  {i.nomi}
                </td>
                <td className={css.OrdersDetailedTd}>{i.soni}</td>
                <td className={css.OrdersDetailedTd}>{i.price}</td>
                <td className={css.OrdersDetailedTd}>
                  {numberFormat(i.summa)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Collapse.Panel>
    );
  });

  if (isLoding === true) {
    return <Preloader />;
  }
  if (ordersReport.length === 0) {
    return <Loding />;
  }

  return (
    <div>
      <AutoCenter>
        {" "}
        <Title style={{ color: "#ff8f1f", marginTop: "10px" }} level={4}>
          {ordersReport[0].name}
        </Title>{" "}
      </AutoCenter>
      <>
        <Collapse accordion>{DataUser}</Collapse>
      </>
    </div>
  );
};
