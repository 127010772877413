import React from "react";
import useShop from "util/shop/useShop";
import AgentSearchForm from "./AgentSearchForm";
import { requestFetch } from "Fetch/requestFetch";
import { Collapse } from "antd-mobile";
import { InfoTable } from "./InfoTable";
import { useState } from "react";

export default function AgentSearchOrderPage() {
  const { dukonlar } = useShop();
  const [orders, setOrders] = useState([]);
  const [product, setProduct] = useState([]);
  const [noData, setNoData] = useState(true);

  function onSearchOrder(val) {
    const value = {
      ...val,
      sana1: val["sana1"].unix(),
      sana2: val["sana2"].unix(),
    };
    requestFetch({ path: "get-orders-done", value })
      .then((response) => {
        if (response.status === 200) {
          setOrders(response.data);
          if (response.data.length != 0) {
            setNoData(false);
          }
        }
      })
      .catch((err) => {});
  }

  function onOrderItem(id) {
    setProduct([]);
    requestFetch({ path: "get-orders-item", value: { id: id } }).then(
      (response) => {
        if (response.status === 200) {
          setProduct(response.data);
        }
      }
    );
  }

  return (
    <>
      <Collapse>
        <Collapse.Panel key="1" title="Поиск...">
          <AgentSearchForm onSearchOrder={onSearchOrder} shop={dukonlar} />
        </Collapse.Panel>
      </Collapse>
      {noData && (
        <p style={style.textStyle}>
          <span style={style.textSpan}>
            В этом окне можно просмотреть историю заказов <br /> начните поиск
            для просмотра...{" "}
          </span>
        </p>
      )}
      {!noData && (
        <InfoTable
          data={orders}
          onOrderItem={onOrderItem}
          product={product}
          noData={noData}
        />
      )}
    </>
  );
}

const style = {
  textStyle: {
    height: "calc(100vh - 400px)",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  textSpan: {},
};
